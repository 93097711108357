import React, { useEffect, useState } from 'react';
import { GiHandTruck } from 'react-icons/gi';
import { MdLocalHotel } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { PrimaryTitleGray } from '../../base/colors';
import { InputText, PrimaryButton, SecondaryButton } from '../../base/Inputs';
import { Spinner } from '../../base/Spinners';
import { Table } from '../../base/table';
import { LargeTile } from '../../components/gridsystem/gridsystem';
import { WideLayout } from '../../components/layout/wideLayout';
import { Panel } from '../../components/panels/panel';
import { SimplePopup } from '../../components/popup';
import { getCustomerBallast } from '../../services/collectionService';
import { usePortalStore } from '../../store';
import { useCollectionStore } from '../../stores/collectionStore';
import { formatCPF } from '../../utils/utils';

export const groupBySegment = (list) => {
  const map = {};
  list.forEach((l) => {
    if (!map[l.segment]) {
      map[l.segment] = {
        segment: l.segment,
        data: [],
      };
    }
    map[l.segment].data.push(l);
  });
  return map;
};
const MainPanels = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  const selectedPatient = useCollectionStore((state) => state.selectedPatient);
  const setConfiguredCollection = useCollectionStore(
    (state) => state.setConfiguredCollection
  );
  const equipmentList = useCollectionStore((state) => state.equipmentList);
  const setEquipmentList = useCollectionStore(
    (state) => state.setEquipmentList
  );
  const collectionSelected = useCollectionStore(
    (state) => state.collectionSelected
  );
  const setCollectionSelected = useCollectionStore(
    (state) => state.setCollectionSelected
  );
  const getKeptItems = useCollectionStore((state) => state.getKeptItems);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const [eqQty, setEqQty] = useState({});
  useEffect(() => {
    //buscar todos os equipamentos alocados no cliente
    (async () => {
      setIsLoading(true);
      let equipments = await getCustomerBallast(selectedPatient.cpf);
      equipments = equipments
        .filter((x) => x.segment.toLowerCase() !== 'descartável' && x.qty !== 0)
        .sort((equip1, equip2) => {
          const text = 'Acessórios';
          const equip1SubCat = equip1.subCategory;
          const equip2SubCat = equip2.subCategory;
          const hasText1 = equip1SubCat?.includes(text);
          const hasText2 = equip2SubCat?.includes(text);
          if (hasText1 && !hasText2) {
            return 1;
          } else if (!hasText1 && hasText2) {
            return -1;
          } else {
            return 0;
          }
        });

      // Add generic cylinder to the list
      equipments.push({
        category: 'Oxigenoterapia',
        name: 'Cilindro genérico',
        id: '80000017',
        segment: 'Cilindro',
        subCategory: 'Cilindros',
        qty: 1,
        accessories: [],
      });

      setConfiguredCollection(null);
      const mapAccEq = {};
      equipments.forEach((eq, idx) => {
        eq.accessories = eq.accessories[0] ? eq.accessories[0] : [];
        eqQty[eq.id] = eq.qty;
        mapAccEq[eq.id] = {
          parent: false,
        };
      });

      setCollectionSelected(mapAccEq);
      setEquipmentList(equipments);
      setIsLoading(false);
    })();
  }, []);

  const selectEquipment = (id, checked) => {
    const idInt = parseInt(id, 10);
    const equipment = equipmentList.filter(
      (x) => x.id === idInt || x.id === id
    )[0];
    if (checked && equipment.accessories.length > 0) {
      //tem q checar todos os filhos
      const clone = JSON.parse(JSON.stringify(collectionSelected));
      const mapAcc = {};
      equipment.accessories.forEach((acc) => {
        if (clone[acc.id]) {
          clone[acc.id].parent = true;
        }
      });
      mapAcc.parent = checked;
      clone[id] = mapAcc;
      setCollectionSelected(clone);
    } else {
      const clone = JSON.parse(JSON.stringify(collectionSelected));
      clone[id].parent = checked;
      if (equipment.accessories.length > 0) {
        equipment.accessories.forEach((acc) => {
          if (clone[acc.id]) {
            clone[acc.id].parent = checked;
          }
        });
      }
      setCollectionSelected(clone);
    }
  };
  const uncheckItem = (eqId, id, checked) => {
    const clone = JSON.parse(JSON.stringify(collectionSelected));
    clone[eqId][id] = checked;
    setCollectionSelected(clone);
  };

  const moveNextToResume = () => {
    const collect = [];
    equipmentList.forEach((eq) => {
      eq.collect = collectionSelected[eq.id].parent;
      if (!eq.collect) {
        eq.qty = eqQty[eq.id];
      }
    });
    equipmentList.forEach((eq) => {
      const c = { ...eq };
      c.accessories = eq.accessories.filter((x) => x.collect === true);

      if (c.accessories.length > 0 || c.collect === true) {
        collect.push(c);
      }
    });
    setConfiguredCollection(collect);
    navigate('/collectEquipments/finishCollectEquipments');
  };

  const shouldShowProceed = () => {
    return (
      JSON.stringify(collectionSelected).toLowerCase().indexOf('true') >= 0
    );
  };
  const toggleTableQuantity = (valor, id, idx) => {
    if (valor <= eqQty[id] && valor > 0 && collectionSelected[id].parent) {
      equipmentList[idx].qty = valor;
      setEquipmentList(equipmentList);
    }
  };

  const getMergedEquipmentList = () => {
    const list = equipmentList.map((equipment, idx) => {
      return [
        {
          select: (
            <input
              type="checkbox"
              checked={collectionSelected[equipment.id].parent ?? false}
              onChange={(e) =>
                selectEquipment(e.target.value, e.target.checked)
              }
              value={equipment.id}
            />
          ),
          name: equipment.name,
          category: equipment.category,
          subCategory: equipment.subCategory,
          serialNumber:
            equipment.serialNumber === '0' ? '-' : equipment.serialNumber,
          qty: (
            <InputText
              type="number"
              value={equipment.qty}
              min={1}
              max={eqQty[equipment.id]}
              onChange={(e) =>
                toggleTableQuantity(e.target.value, equipment.id, idx)
              }
            ></InputText>
          ), //equipment.qty,
        } /* ,equipment.accessories.map(acc => {
            return {
                select:<input type="checkbox" onChange={(e)=>uncheckItem(equipment.id,e.target.value,e.target.checked)} value={acc.id} checked={collectionSelected[equipment.id][acc.id] ?? false} />,
                name: acc.name,
                category: acc.category,
                subCategory: acc.subCategory,
                serialNumber: acc.serialNumber,
                qty:acc.qty
            }
        }) */,
      ];
    });
    return list.flat().flat();
  };
  const toggleAll = (checked) => {
    const clone = {};
    equipmentList.forEach((equipment) => {
      const mapAcc = {};
      mapAcc.parent = checked;
      clone[equipment.id] = mapAcc;
    });
    setCollectionSelected(clone);
  };
  const configCollection = () => {
    return (
      <>
        <div style={{ overflow: 'auto' }}>
          {!isLoading ? (
            equipmentList.length > 0 ? (
              <Table
                expandable
                header={[
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 'auto',
                    }}
                  >
                    <input
                      onClick={(e) => toggleAll(e.target.checked)}
                      type="checkbox"
                    />
                  </div>,
                  'Equipamentos e Acessórios',
                  'Categoria',
                  'Subcategoria',
                  'Nº Serie',
                  'Qtd',
                ]}
                data={getMergedEquipmentList()}
              ></Table>
            ) : (
              <p className="message-event">
                Este paciente não possui equipamentos e acessórios para serem
                recolhidos.
              </p>
            )
          ) : (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                overflow: 'hidden',
                padding: '20px',
                boxSizing: 'border-box',
              }}
            >
              <Spinner></Spinner>
            </div>
          )}
        </div>
        <div style={{ height: '100px' }}></div>
      </>
    );
  };

  const confirmChanges = () => {
    return (
      <div style={{ position: 'relative', fontSize: '14px' }}>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: 'solid 1px #ccc',
              marginBottom: '5px',
            }}
          >
            <MdLocalHotel
              style={{ fontSize: '25px', marginRight: '9px', color: '#318300' }}
            ></MdLocalHotel>
            <div style={{ fontSize: '16px' }}>Itens que serão mantidos</div>
          </div>
          <div>
            <ul style={{ margin: '0', padding: '0' }}>
              {Object.values(
                groupBySegment(
                  getKeptItems().filter(
                    (x, idx) =>
                      x.collect === false ||
                      (x.collect === true && x.qty < eqQty[x.id])
                  )
                )
              ).map((ss, idx) => (
                <li style={{ listStyle: 'none' }} key={idx}>
                  <p>{ss.segment}</p>
                  {ss.data.map((acc, j) => (
                    <div
                      style={{
                        listStyle: 'none',
                        padding: '6px 15px',
                        background: '#efefef',
                        borderRadius: '10px',
                        marginBottom: '5px',
                        marginLeft: '35px',
                      }}
                      key={j}
                    >
                      {eqQty[acc.id] !== acc.qty &&
                      collectionSelected[acc.id].parent
                        ? eqQty[acc.id] - acc.qty
                        : eqQty[acc.id]}
                      x {acc.name}
                    </div>
                  ))}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: 'solid 1px #ccc',
              marginBottom: '5px',
              marginTop: '10px',
            }}
          >
            <GiHandTruck
              style={{ fontSize: '30px', marginRight: '9px', color: '#b34141' }}
            ></GiHandTruck>
            <div style={{ fontSize: '16px' }}>Itens que serão recolhidos</div>
          </div>
          <div>
            <ul style={{ margin: '0', padding: '0' }}>
              {Object.values(
                groupBySegment(getKeptItems().filter((x) => x.collect === true))
              ).map((ss, idx) => (
                <li style={{ listStyle: 'none' }} key={idx}>
                  <p>{ss.segment}</p>
                  {ss.data.map((acc, j) => (
                    <div
                      style={{
                        listStyle: 'none',
                        padding: '6px 15px',
                        background: '#efefef',
                        borderRadius: '10px',
                        marginBottom: '5px',
                        marginLeft: '35px',
                      }}
                      key={j}
                    >
                      {acc.qty}x {acc.name}
                    </div>
                  ))}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '25px',
          }}
        >
          <div style={{ width: '150px' }}>
            <SecondaryButton onClick={() => setStep(0)}>Voltar</SecondaryButton>
          </div>
          <div style={{ marginLeft: '15px' }}>
            <PrimaryButton onClick={() => moveNextToResume()}>
              Prosseguir
            </PrimaryButton>{' '}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <LargeTile margin={'15px'}>
        <h3 style={{ color: PrimaryTitleGray }}>
          {step === 0 ? 'Recolhimento' : 'Confirme o pedido de Recolhimento'}
        </h3>
        <Panel style={{ padding: '20px' }}>
          {step === 0 ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <h4
                  style={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    padding: '0',
                    margin: '0',
                    marginBottom: '15px',
                  }}
                >
                  {selectedPatient.name} - CPF: {formatCPF(selectedPatient.cpf)}
                </h4>
              </div>
              <div style={{ marginLeft: '15px' }}>
                <PrimaryButton
                  style={{ textAlign: 'right', marginBottom: '15px' }}
                  onClick={() => setStep(1)}
                  disabled={!shouldShowProceed()}
                >
                  Prosseguir
                </PrimaryButton>
              </div>
            </div>
          ) : (
            <h4
              style={{
                fontWeight: 'bold',
                padding: '0',
                margin: '0',
                marginBottom: '15px',
              }}
            >
              {selectedPatient.name} - CPF: {formatCPF(selectedPatient.cpf)}
            </h4>
          )}

          {step === 0 ? configCollection() : confirmChanges()}
        </Panel>
      </LargeTile>
    </>
  );
};

export const EquipmentCollectionPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <WideLayout
      outlet={isTabletOrMobile ? MainPanels() : MainPanels()}
    ></WideLayout>
  );
};
