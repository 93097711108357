import React, { useEffect, useState } from 'react';
import { AiOutlineCopy } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';
import { BsFilePdf, BsWhatsapp } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { VscClose } from 'react-icons/vsc';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BlueText, Green3, PrimaryTitleGray } from '../../base/colors';
import {
  InputText,
  LighterGreenButton,
  PrimaryButton,
  SecondaryButton,
} from '../../base/Inputs';
import { Spinner } from '../../base/Spinners';
import { ExpandableTable, Table } from '../../base/table';
import { LargeTile } from '../../components/gridsystem/gridsystem';
import { WideLayout } from '../../components/layout/wideLayout';
import { Panel } from '../../components/panels/panel';
import { SimplePopup } from '../../components/popup';
import {
  getEquipmentsByCPF,
  searchAssistancePerEquipment,
} from '../../services/assistanceService';
import { saveDocument } from '../../services/documentService';
import { getProductsGuides } from '../../services/productService';
import { usePortalStore } from '../../store';
import { useTechnicalAssistanceStore } from '../../stores/technicalAssistanceStore';
import {
  downloadPDF,
  formatCPF,
  getLastFromSplit,
  getPdfDownloadUrl,
} from '../../utils/utils';
import { ArrowForward } from '@mui/icons-material';
import { Tooltip } from '../../components/common/Tooltip';

const MainPanels = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  const selectedPatient = useTechnicalAssistanceStore(
    (state) => state.selectedPatient
  );
  const setTypedSearch = useTechnicalAssistanceStore(
    (state) => state.setTypedSearch
  );
  const typedSearch = useTechnicalAssistanceStore((state) => state.typedSearch);
  const selectedEquipments = useTechnicalAssistanceStore(
    (state) => state.selectedEquipments
  );
  const setSelectedEquipment = useTechnicalAssistanceStore(
    (state) => state.setSelectedEquipment
  );
  const equipmentList = useTechnicalAssistanceStore(
    (state) => state.equipmentList
  );
  const setEquipmentList = useTechnicalAssistanceStore(
    (state) => state.setEquipmentList
  );
  const selectedSearch = useTechnicalAssistanceStore(
    (state) => state.selectedSearch
  );
  const setSelectedSearch = useTechnicalAssistanceStore(
    (state) => state.setSelectedSearch
  );
  const [search, setSearch] = useState([]);
  const [guides, setGuides] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showCreateLinkPopup, setCreateLinkPopup] = useState(false);
  const [isGeneratingLink, setGeneratingLink] = useState('');
  const [currentSelectedEquipment, setCurrentSelectedEquipment] = useState(-1);
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedGuides, setSelectedGuides] = useState({});
  const [selectedSearchFinish, setSelectedSearchFinish] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    //buscar todos os equipamentos alocados no cliente
    (async () => {
      const keepState = searchParams.get('keepState');
      if (keepState !== 'true') {
        setIsLoading(true);
        let equipments = await getEquipmentsByCPF(
          currentUser.cnpj,
          selectedPatient.cpf
        );
        equipments = equipments.filter(
          (eq) => eq.qty !== 0 && eq.subCategory !== 'Cilindros'
        );
        setSelectedEquipment({}, '');
        setEquipmentList([...equipments]);
        const g = await getProductsGuides(equipments.map((x) => x.id));
        setGuides([...g]);
        setIsLoading(false);
      }
    })();
  }, []);

  const renderSelectedSearch = (eqId, rowIdx) => {
    const rows = (selectedSearch[eqId] ?? []).map((s, idx) => [
      <input
        key={idx}
        type="checkbox"
        value={s.genId}
        onChange={(e) =>
          adjustSelection(eqId, e.target.value, e.target.checked, false)
        }
        checked={(selectedSearchFinish[eqId] ?? {})[s.genId] === true}
      />,
      s.issue,
      s.cause,
      s.howToProceed,
    ]);
    return (
      <div className="table-assistencia1" key={rowIdx}>
        <Table
          header={['', 'Problemas', 'Causas', 'Como proceder']}
          data={rows}
        ></Table>
      </div>
    );
  };

  const selectEquipment = (id, checked, typedSearch) => {
    setSelectedEquipment({ ...selectedEquipments, [id]: checked }, typedSearch);
    if (checked) {
      setCurrentSelectedEquipment(id);
      setShowPopup(true);
      autoSearch(id);
    } else {
      delete selectedSearch[id];
      setSelectedSearch(selectedSearch);
    }
  };

  const proceed = () => {
    Object.keys(selectedSearch).forEach((eqId) => {
      selectedSearch[eqId].forEach((x, idx) => {
        if (!selectedSearchFinish[eqId][x.genId]) {
          selectedSearch[eqId].splice(idx, 1);
        }
      });
    });
    setSelectedSearch(selectedSearch);
    navigate('/technicalAssistance/finishAssistanceOrder');
  };
  const proceedWithGenericRequest = () => {
    setSelectedEquipment({ ...selectedEquipments, 85000378: true }, '');
    navigate('/technicalAssistance/finishAssistanceOrder');
  };

  const loading = () => {
    return (
      <div style={{ textAlign: 'center', position: 'relative' }}>
        <Spinner style={{}} />
      </div>
    );
  };
  const checkSearch = (rowIdx, checked) => {
    adjustSelection(currentSelectedEquipment, rowIdx, checked);
  };

  const adjustSelection = (eqId, rowIdx, checked, mustRemove = true) => {
    const currId = eqId;
    if (!selectedSearch[currId]) {
      selectedSearch[currId] = [];
    }
    if (!selectedSearchFinish[currId]) {
      selectedSearchFinish[currId] = {};
    }
    selectedSearchFinish[currId][rowIdx] = checked;
    if (checked) {
      const toAdd = search.filter((x) => x?.genId === rowIdx)[0];
      if (toAdd) {
        selectedSearch[currId].push(toAdd);
      }
      setSelectedSearch(selectedSearch);
    } else if (mustRemove) {
      const idx = selectedSearch[currId].findIndex((x) => x?.genId === rowIdx);
      if (idx >= 0) {
        selectedSearch[currId].splice(idx, 1);
      }
      setSelectedSearch(selectedSearch);
    }
    setSelectedSearchFinish(JSON.parse(JSON.stringify(selectedSearchFinish)));
  };
  const tableResult = () => {
    return (
      <div>
        <h3 style={{ fontSize: '16px', margin: '5px 1px' }}>
          Resultado da pesquisa
        </h3>
        <div className="table-assistencia2">
          <Table
            header={['', 'Problemas', 'Causas', 'Como proceder']}
            data={search.map((s, idx) => [
              <input
                key={idx}
                type="checkbox"
                value={s.genId}
                onChange={(e) => checkSearch(e.target.value, e.target.checked)}
              />,
              s.issue,
              s.cause,
              s.howToProceed,
            ])}
          ></Table>
        </div>
      </div>
    );
  };

  const autoSearch = (eqId) => {
    setSearchLoading(true);
    (async () => {
      const results = await searchAssistancePerEquipment(
        currentUser.cnpj,
        eqId,
        ''
      );
      setSearchLoading(false);
      setSearch(results);
    })();
  };
  const doSearch = (e, click) => {
    if (click === true || e.key === 'Enter' || e.keyCode === 13) {
      setSearchLoading(true);
      (async () => {
        const results = await searchAssistancePerEquipment(
          currentUser.cnpj,
          currentSelectedEquipment,
          searchTerm
        );
        setSearchLoading(false);
        if (!typedSearch[currentSelectedEquipment]) {
          typedSearch[currentSelectedEquipment] = [];
        }
        typedSearch[currentSelectedEquipment].push(searchTerm);
        setTypedSearch({ ...typedSearch });
        setSearch(results);
      })();
    }
  };
  const closePopup = () => {
    setCurrentSelectedEquipment(-1);
    setShowPopup(false);
    setSearch([]);
    setGeneratingLink('');
  };
  const createLink = () => {
    const docList = [];
    Object.keys(selectedEquipments)
      .filter(
        (x) => selectedEquipments[x] === true && x === currentSelectedEquipment
      )
      .map((id) => equipmentList.filter((x) => x.id == id)[0])
      .forEach((equipment) => {
        const finalDoc = {
          name: equipment.name,
          solutions: selectedSearch[equipment.id.toString()],
        };
        docList.push(finalDoc);
      });
    const document = {
      files: Object.values(selectedGuides),
      search: docList,
    };
    saveDocToShareLink(document);
  };

  const saveDocToShareLink = async (doc) => {
    const key = await saveDocument(doc);
    setGeneratingLink(`${window.location.origin}/link?key=${key.key}`);
  };
  const toggleSelectedGuide = (file, idx) => {
    if (selectedGuides[idx]) {
      delete selectedGuides[idx];
    } else {
      selectedGuides[idx] = file;
    }
    setSelectedGuides({ ...selectedGuides });
  };
  const createLinkPopup = () => {
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <div
          style={{
            display: 'flex',
            alignItens: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h3
            style={{
              display: 'inline-block',
              margin: '0',
              color: PrimaryTitleGray,
              width: '100%',
              float: 'left',
            }}
          >
            Compartilhamento:{' '}
            <span style={{ fontWeight: 'normal' }}>
              Selecione os arquivos que deseja enviar
            </span>
          </h3>
          <VscClose
            style={{ fontSize: '25px', cursor: 'pointer' }}
            onClick={() => setCreateLinkPopup(false)}
          ></VscClose>
        </div>
        {Object.keys(selectedEquipments)
          .filter(
            (x) =>
              selectedEquipments[x] === true && x === currentSelectedEquipment
          )
          .map((id) => equipmentList.filter((x) => x.id == id)[0])
          .map((equipment, idx) => (
            <div style={{ display: 'block' }} key={idx}>
              <div style={{ width: '100%' }}>
                {guides
                  .filter(
                    (g) => parseInt(g.product_code) === parseInt(equipment.id)
                  )
                  .flat()
                  .map((file) =>
                    file.guides.map((guide, jjj) => (
                      <div
                        style={{
                          textAlign: 'left',
                          margin: '10px 10px 10px 0',
                        }}
                        key={jjj}
                      >
                        <div
                          onClick={() =>
                            toggleSelectedGuide(
                              { product_code: file.product_code, guide: guide },
                              jjj
                            )
                          }
                          style={{
                            padding: '10px',
                            backgroundColor: selectedGuides[jjj]
                              ? '#2f7c0021'
                              : 'white',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: 'underline',
                          }}
                        >
                          <BsFilePdf
                            style={{
                              fontSize: '28px',
                              cursor: 'pointer',
                              color: 'red',
                            }}
                          ></BsFilePdf>
                          {getLastFromSplit(guide, '_')}
                        </div>
                      </div>
                    ))
                  )}
              </div>
            </div>
          ))}
        {isGeneratingLink !== '' ? (
          <>
            <div style={{ margin: '15px 0' }}>
              Você pode encaminhar este link por WhatsApp e e-mail.
              <br />
              <span style={{ fontSize: '12px' }}>
                Este link será expirado após 24 Horas
              </span>
            </div>
            {isGeneratingLink !== '' ? (
              <div
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '5px',
                  border: '1px solid #eee',
                  width: '100%',
                  boxSizing: 'border-box',
                  backgroundColor: '#f3fdf6',
                  color: '#255a33',
                }}
              >
                <div style={{ wordBreak: 'break-all' }}>{isGeneratingLink}</div>
                <div style={{ padding: '5px' }}>
                  <AiOutlineCopy
                    style={{ cursor: 'pointer', fontSize: '28px' }}
                    onClick={() =>
                      navigator.clipboard.writeText(isGeneratingLink)
                    }
                  ></AiOutlineCopy>
                </div>
              </div>
            ) : (
              <span>Criando o link</span>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <div>Compartilhe </div>
              <a
                href={`https://api.whatsapp.com/send?text=${isGeneratingLink}`}
                target={'_blank'}
                rel="noreferrer"
              >
                <BsWhatsapp
                  style={{
                    fontSize: '30px',
                    padding: '0 10px',
                    color: Green3,
                    paddingRight: '10px',
                  }}
                ></BsWhatsapp>
              </a>
              <a
                href={`mailto:${selectedPatient.email}?subject=Portal Homecare&body=${isGeneratingLink}`}
                target={'_blank'}
                rel="noreferrer"
              >
                <HiOutlineMail
                  style={{
                    fontSize: '36px',
                    padding: '10px 10px 10px 0',
                    color: Green3,
                  }}
                ></HiOutlineMail>
              </a>
            </div>
          </>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <PrimaryButton onClick={() => createLink()}>
              Gerar Link de Compartilhamento
            </PrimaryButton>
          </div>
        )}
      </div>
    );
  };
  const openSharePopup = () => {
    setGeneratingLink('');
    setCreateLinkPopup(true);
  };
  const showHelp = () => {
    return (
      <div style={{ boxSizing: 'border-box', padding: '10px' }}>
        <div style={{ marginBottom: '9px' }}>Guia de Soluções de Problemas</div>
        <div
          style={{
            display: 'flex',
            alignItens: 'center',
            justifyContent: 'space-between',
            marginBottom: '15px',
          }}
        >
          <h3 style={{ margin: '0' }}>
            {
              (
                (Object.keys(selectedEquipments)
                  .filter(
                    (x) =>
                      selectedEquipments[x] === true &&
                      parseInt(x) === parseInt(currentSelectedEquipment)
                  )
                  .map(
                    (id) =>
                      equipmentList.filter(
                        (x) => parseInt(x.id) === parseInt(id)
                      )[0]
                  ) ?? [])[0] ?? {}
              )?.name
            }
          </h3>
          <div
            style={{
              fontSize: '25px',
              marginTop: '-27px',
              position: 'relative',
            }}
          >
            <VscClose
              onClick={closePopup}
              style={{ height: '35px', cursor: 'pointer' }}
            ></VscClose>
          </div>
        </div>

        <div
          style={{
            flex: '1',
            marginBottom: '15px',
            display: isTabletOrMobile ? 'block' : 'flex',
          }}
        >
          <div
            style={{
              display: 'flex',
              boxSizing: 'border=box',
              flex: '1',
              marginBottom: isTabletOrMobile ? '15px' : '0px',
            }}
          >
            <InputText
              onKeyUp={(e) => doSearch(e)}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: '100%' }}
              placeholder={'Informe a dúvida que deseja assistência'}
            />
            <LighterGreenButton
              onClick={(e) => doSearch(e, true)}
              style={{ marginLeft: '5px' }}
            >
              <BiSearch
                style={{ fontSize: '30px', cursor: 'pointer', color: '#fff' }}
              ></BiSearch>
            </LighterGreenButton>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              flex: '1',
              boxSizing: 'border=box',
              paddingLeft: isTabletOrMobile ? '0px' : '5px',
              paddingRight: isTabletOrMobile ? '0px' : '5px',
            }}
          >
            {/* <LighterGreenButton onClick={(e)=> doSearch(e,true)} style={{}}>
                            <BiSearch style={{fontSize:"30px", cursor:"pointer",color:"#fff"}}></BiSearch>
                        </LighterGreenButton> */}
            <SecondaryButton
              onClick={() => openSharePopup()}
              style={{ marginRight: '10px' }}
            >
              Compartilhar
            </SecondaryButton>
            <PrimaryButton onClick={(e) => closePopup(e)}>
              Concluir
            </PrimaryButton>
          </div>
        </div>
        {Object.keys(selectedEquipments)
          .filter(
            (x) =>
              selectedEquipments[x] === true && x === currentSelectedEquipment
          )
          .map((id) => equipmentList.filter((x) => x.id == id)[0])
          .map((equipment, idx) => (
            <div style={{ display: 'block' }} key={idx}>
              <div style={{ display: 'flex', width: '100%' }}>
                {guides
                  .filter(
                    (g) => parseInt(g.product_code) === parseInt(equipment.id)
                  )
                  .map((file) =>
                    file.guides.map((guide, jjj) => (
                      <div
                        style={{
                          textAlign: 'left',
                          margin: '10px 10px 10px 0',
                          color: BlueText,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        key={`${jjj}`}
                      >
                        <a
                          onClick={() =>
                            downloadPDF(
                              getPdfDownloadUrl(file.product_code, guide)
                            )
                          }
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: 'black',
                          }}
                        >
                          <BsFilePdf
                            style={{
                              fontSize: '19px',
                              cursor: 'pointer',
                              color: 'red',
                            }}
                          ></BsFilePdf>
                          {getLastFromSplit(guide, '_')}
                        </a>
                      </div>
                    ))
                  )}
              </div>
            </div>
          ))}
        <div>
          {selectedSearch[currentSelectedEquipment] ? (
            <div>
              <h3 style={{ fontSize: '16px', margin: '5px 1px' }}>
                Selecionadas
              </h3>
              <div
                className="table-assistence3"
                style={{
                  borderBottom: 'dashed 2px #128d15',
                  marginBottom: '15px',
                  maxHeight: '300px',
                  overflow: 'auto',
                }}
              >
                <Table
                  header={['Problema', 'Causa', 'Solução']}
                  data={selectedSearch[currentSelectedEquipment].map((x) => {
                    const c = { ...x };
                    delete c.genId;
                    return c;
                  })}
                ></Table>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div style={{ overflow: 'auto', maxHeight: '300px' }}>
          {searchLoading ? loading() : tableResult()}
        </div>
      </div>
    );
  };

  const showExpandable = (id) =>
    selectedSearch &&
    selectedSearch[id] &&
    Object.keys(selectedSearch[id]).length > 0;
  return (
    <>
      <LargeTile margin={'15px'}>
        <h3>Assistência Técnica</h3>
        <Panel style={{ padding: '20px' }}>
          <div
            className="titulo"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <h4 style={{ fontWeight: 'bold', flex: 1 }}>
              {selectedPatient.name} - CPF: {formatCPF(selectedPatient.cpf)}
            </h4>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Tooltip title="Validação e Processamento de Pedidos">
                <SecondaryButton
                  onClick={() => proceedWithGenericRequest()}
                  style={{
                    boxSizing: 'border-box',
                    height: '30px',
                  }}
                  disabled={isLoading}
                >
                  <ArrowForward />
                </SecondaryButton>
              </Tooltip>
              <PrimaryButton
                disabled={
                  equipmentList.length === 0 ||
                  Object.keys(selectedEquipments).length === 0
                }
                onClick={() => proceed()}
              >
                Prosseguir
              </PrimaryButton>
            </div>
          </div>
          <div style={{ overflow: 'auto' }}>
            {equipmentList.length > 0 ? (
              <ExpandableTable
                expandable={false}
                header={[
                  '',
                  'Equipamentos e Acessórios',
                  'Categoria',
                  'Subcategoria',
                  'Nº Serie',
                  'Quantidade',
                ]}
                data={equipmentList.map((equipment, idx) => {
                  return {
                    a: (
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          selectEquipment(e.target.value, e.target.checked)
                        }
                        value={equipment.id}
                        checked={selectedEquipments[equipment.id] ?? false}
                      />
                    ),
                    name: equipment.name,
                    category: equipment.category,
                    subCategory: equipment.subCategory,
                    serialNumber:
                      equipment.serialNumber == 0
                        ? '-'
                        : equipment.serialNumber,
                    qty: equipment.qty,
                    showExpandPanelIcon: false,
                  };
                })}
              ></ExpandableTable>
            ) : (
              <div>
                <div className="message-event">
                  {' '}
                  {isLoading ? (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <Spinner></Spinner>
                    </div>
                  ) : (
                    'Detectamos uma incompatibilidade nas informações do cadastro. Por favor, siga com o pedido clicando no botão acima e descreva sua solicitação. Faremos a correção e atenderemos seu pedido.'
                  )}
                </div>
              </div>
            )}
          </div>
        </Panel>
      </LargeTile>
      {showPopup ? (
        <SimplePopup
          height={`${window.innerHeight - 0}px`}
          outlet={showHelp()}
        ></SimplePopup>
      ) : (
        <></>
      )}
      {showCreateLinkPopup ? (
        <SimplePopup
          className="pop-up-assistance"
          height={`${window.innerHeight - 0}px`}
          width={800}
          outlet={createLinkPopup()}
        ></SimplePopup>
      ) : (
        <></>
      )}
    </>
  );
};

export const TechnicalAssistancePage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <WideLayout
      outlet={isTabletOrMobile ? MainPanels() : MainPanels()}
    ></WideLayout>
  );
};
